<template>
	<div class="change-password">
		<div class="main-title">
			<span class="name">修改密码</span>
		</div>
		<el-form :model="resetForm" :rules="resetFormRules" ref="resetForm" status-icon label-width="100px">
			<el-form-item label="原密码：" prop="oldpassword">
				<el-input type="password" v-model="resetForm.oldpassword" auto-complete="off"></el-input>
			</el-form-item>
			<el-form-item label="新密码：" prop="newpwd">
				<el-input type="password" v-model="resetForm.newpwd" auto-complete="off"></el-input>
			</el-form-item>
			<el-form-item label="确认密码：" prop="password">
				<el-input type="password" v-model="resetForm.password" auto-complete="off"></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click.native.prevent="passwordChange">确认修改</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
	import {
		updateUserPwd
	} from '@/api/user'
	export default {
		data() {
			// var validatePass = (rule, value, callback) => {
			// 	if (!value) {
			// 		callback(new Error("请输入新密码"));
			// 	} else if (value.toString().length < 6 || value.toString().length > 18) {
			// 		callback(new Error("密码长度为6-18位"));
			// 	} else {
			// 		callback();
			// 	}
			// };
			var validatePass2 = (rule, value, callback) => {
				if (value === "") {
					callback(new Error("请再次输入密码"));
				} else if (value !== this.resetForm.newpwd) {
					callback(new Error("两次输入密码不一致!"));
				} else {
					callback();
				}
			};
			return {
				resetForm: {
					password: "",
					oldpassword: "",
				},
				resetFormRules: {
					oldpassword: [{
						required: true,
						message: "请输入旧密码",
						trigger: 'blur'
					}],
					newpwd: [{
							required: true,
							message: '请输入新密码',
							trigger: 'blur'
						},
						{
							min: 8,
							max: 18,
							message: '密码长度为8-18位数',
							trigger: 'blur'
						},
						{
							trigger: 'blur',
							validator: (rule, value, callback) => {
								var passwordreg = /(?![A-Z]*$)(?![a-z]*$)(?![0-9]*$)(?![^a-zA-Z0-9]*$)/
								// console.log(passwordreg.test(value))
								if (!passwordreg.test(value)) {
									callback(
										new Error(
											'密码必须由大写字母、小写字母、数字、特殊符号中的2种及以上类型组成!'
										)
									)
								} else {
									callback()
								}
							}
						}
					],
					// newpwd: [{
					// 	required: true,
					// 	validator: validatePass,
					// 	trigger: 'blur'
					// }],
					password: [{
						required: true,
						validator: validatePass2,
						trigger: "blur"
					}]
				}
			};
		},
		beforeDestroy(){
			if(localStorage.getItem('isFirstLogin') == "true"){
				this.$message({
					type: "error",
					message: "密码过于简单，请修改密码!",
				});
				this.$router.push("/changePassword")
			}
		},
		methods: {
			passwordChange() {
				this.$refs.resetForm.validate(valid => {
					if (valid) {
						updateUserPwd(this.resetForm).then(res => {
							if (res.code == 0) {
								this.$message({
									message: '成功修改密码，请重新登录！',
									type: 'success'
								});
								localStorage.clear()
								this.name = ''
								this.$router.push("/")
							}
						})

					}
				});
			}
		},
	};
</script>

<style lang="scss" scoped>
	.change-password {
		max-width: 600px;
		margin: 20vh auto 0;
		padding: 30px;
		border-radius: 10px;
		background-color: #FFF;
		box-sizing: border-box;
		box-shadow: 0px 0px 15px rgba($color:#000, $alpha:0.5);

		.main-title {
			font-size: 20px;
			margin: 0 0 30px 0;
		}

		.el-form-item {
			margin-bottom: 22px;
		}

		::v-deep .el-input__validateIcon {
			display: none;
		}
	}
</style>
